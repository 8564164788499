window.___gcfg = {
	lang: "en-GB",
	parsetags: "onload"
};
function loadSliders() {
	$('.homepage-gallery__inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 7,
		prevArrow: "<button class='homepage-gallery__button homepage-gallery__button--prev' aria-label='Previous' type='button'></button>",
		nextArrow: "<button class='homepage-gallery__button homepage-gallery__button--next' aria-label='Next' type='button'></button>",
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}
var sliderScript = document.getElementById("deferredSlickScript");
sliderScript.addEventListener('load', function () {
	loadSliders();
});
$(document).ready(function () {
	var packagesLink = $('a[href="/packages/"]');
	packagesLink.attr('title', 'Packages');
	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});
	$("a.has-tooltip").tooltip({
		placement: "auto"
	});
	if ($('#BannerPanel').length > 0 && window.matchMedia('(min-width:768px)').matches) {
		$('#Logo').addClass("HomepageAnim");
	}
});
